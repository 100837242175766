import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'

import { PP } from '../components/legal'

import {
  VisbilityContainer,
  CommonPose
} from '../components/animations'

const PrivacyPolicy = props => (
  <>
    <VisbilityContainer>
      <Header />
      <div className="legal">
        <div className="legal-wrapper">
          <div className="container">
            { PP }
          </div>
        </div>
      </div>
      <Footer />
    </VisbilityContainer>
  </>
)

export default PrivacyPolicy
